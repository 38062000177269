import { Link } from "react-router-dom"

type props = {
    title : string
    excerpt : string
    slug : string
    image : string
}
export default function ServiceCard({title, excerpt, slug, image} : props){

    return(
        <div className="group bg-white bg-opacity-10 hover:bg-secondary hover:bg-opacity-20  mx-auto flex flex-col w-80 rounded-tl-[3rem] rounded-br-[3rem] overflow-hidden shadow h-fit">
            <img src={image} className="block w-full h-40 object-cover" alt="Smart Sports Agency" />
            <div className="relative p-4">
                <img src="/images/smart-sports-agency-banner-circle.png" alt="" className="absolute w-14 top-0 -translate-y-1/2" />
                <h3 className="relative z-10 font-semibold text-xl uppercase">{title}</h3>
                <p className="relative z-10 my-5">{excerpt}</p>
            </div>
        </div>
    )
}
