import { Link } from "react-router-dom"

type props = {
    className? : string
    whiteOne? : boolean
}
export default function Logo({ className, whiteOne } : props){
    return (
        <Link to="/" className="w-fit">
            {/*<img src={ whiteOne ? "/images/logos/smart-sport-agency-white-logo.png" : "/images/logos/lg-new.png"} className={` object-contain h-11 ${className}`} alt="Smart Sports Agency Logo" />*/}
            <img src={ whiteOne ? "/images/logos/smart-sport-agency-white-logo.png" : "/images/logos/ssa-logo.png"} className={` object-contain h-11 ${className}`} alt="Smart Sports Agency Logo" />
        </Link>
    )
}