/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Logo } from ".";
import Container from "./container";

export default function Header(){
    const location = useLocation()
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    useEffect(() =>{
        setShowMobileMenu(false)
        if(location.hash){
            console.log(location.hash)
            document.getElementById(location.hash.substring(1))?.scrollIntoView({behavior : "smooth",  })
        }else{
            window.scrollTo( 0, 0 )
        }

        const onScroll = () => setShowMobileMenu(false)
        window.addEventListener("scroll", onScroll)

        return () => window.removeEventListener("scroll", onScroll)

    }, [location])
    
    return (
        <header className="fixed z-50 top-0 w-full bg-white shadow-sm">
            <Container className="flex justify-between items-center py-5">
                <Logo />
                <nav className="hidden sm:flex gap-8 text-secondary font-semibold">
                    <NavLink className={ ({isActive}) => `py-1 relative after:absolute after:bottom-0 after:left-0 after:h-[.1rem] ${isActive ? "after:w-full" : "after:w-0 hover:after:w-full" } dafter:bg-primary`} to="/">Accueil</NavLink>
                    <NavLink className={ ({isActive}) => `py-1 relative after:absolute after:bottom-0 after:left-0 after:h-[.1rem] ${isActive ? "after:w-full" : "after:w-0 hover:after:w-full" } dafter:bg-primary`} to="#about-us">A propos</NavLink>
                    <NavLink className={ ({isActive}) => `py-1 relative after:absolute after:bottom-0 after:left-0 after:h-[.1rem] ${isActive ? "after:w-full" : "after:w-0 hover:after:w-full" } dafter:bg-primary`} to="#services">Services</NavLink>
                    <NavLink className={ ({isActive}) => `py-1 relative after:absolute after:bottom-0 after:left-0 after:h-[.1rem] ${isActive ? "after:w-full" : "after:w-0 hover:after:w-full" } dafter:bg-primary`} to="#contact">Contact</NavLink>
                </nav>
                <div className="hidden md:flex gap-8 text-primary">
                    <a className="fab fa-facebook-f" target="_blank" href="https://www.facebook.com/profile.php?id=100088092972198&mibextid=LQQJ4d" rel="noreferrer" />
                    <a className="fab fa-twitter" target="_blank" href="" />
                    <a className="fab fa-instagram" target="_blank" href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Fsmartsportagency%3Ffbclid%3DIwAR0NvLUVLS-ZMK5mcCX6CwSPKe1W5ZwivDjIibNzMdgVG7dztuuKQHxbPpg&h=AT1k_90y_wUSmY5S8u12csC9YKk7iXLDt2T4498KC_WpskpG14iZ-19LrB9sApRXShwFzI43r6SVuBJ9C626S4v9xdvnf10pStsmE9f01B_wJZvTz-yaaJWcDxyKeIeLWsgf" rel="noreferrer" />
                    <a className="fab fa-linkedin-in" target="_blank" href="https://www.linkedin.com/company/smart-sport-agency" rel="noreferrer" />
                </div>
                {/* <Link to="/contact" className="hidden sm:block px-4 py-2 text-white bg-secondary hover:bg-secondary-deep rounded-md">Contact us</Link> */}
                <div className="sm:hidden flex gap-4">
                    <button className={`fas ${showMobileMenu ? "fa-close rotate-180 text-white" : "fa-bars"} text-3xl relative z-20`} onClick={() => setShowMobileMenu(prev => !prev)} onBlur={ () => setShowMobileMenu(false)} />
                    <div className={`absolute z-10 top-0 bottom-0 ${showMobileMenu ? "left-0" : "-left-[200%]"} w-full bg-primary text-white  min-h-screen flex sm:hidden flex-col gap-4 `}>
                        <img src="/images/smart-sports-agency-competition.jpg" alt="" className="absolute  w-full h-full object-cover object-center blur-sm" />
                        <div className="absolute z-10 w-full h-full bg-default bg-opacity-50" />
                        <Logo whiteOne className="relative z-10 mt-6 h-16" />
                        <nav className="relative z-10 my-8 text-xl flex flex-col gap-4">
                            <Link className="text-center p-2 border-b border-b-white" to="/">Accueil</Link>
                            <Link className="text-center p-2 border-b border-b-white" to="#about">A propos</Link>
                            <Link className="text-center p-2 border-b border-b-white" to="#services">Services</Link>
                            <Link className="text-center p-2 border-b border-b-white" to="#contact">Contact</Link>
                        </nav>
                        <div className="relative z-10 md:hidden flex justify-center  gap-8 ">
                            <a className="fab fa-facebook-f" target="_blank" href="https://www.facebook.com/profile.php?id=100088092972198&mibextid=LQQJ4d" rel="noreferrer" />
                            <a className="fab fa-twitter" target="_blank" href="" />
                            <a className="fab fa-instagram" target="_blank" href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Fsmartsportagency%3Ffbclid%3DIwAR0NvLUVLS-ZMK5mcCX6CwSPKe1W5ZwivDjIibNzMdgVG7dztuuKQHxbPpg&h=AT1k_90y_wUSmY5S8u12csC9YKk7iXLDt2T4498KC_WpskpG14iZ-19LrB9sApRXShwFzI43r6SVuBJ9C626S4v9xdvnf10pStsmE9f01B_wJZvTz-yaaJWcDxyKeIeLWsgf" rel="noreferrer" />
                            <a className="fab fa-linkedin-in" target="_blank" href="https://www.linkedin.com/company/smart-sport-agency" rel="noreferrer" />
                        </div>
                        
                        <a href="tel:+243994753107" className="mt-4 flex justify-center items-center gap-x-2 relative z-10">
                            <i className="fa-solid fa-headphones" />
                            <span className="">+243 994 753 107</span>
                        </a>
                        <div className="relative z-10 flex justify-center items-center gap-x-2">
                            <i className="fa-solid fa-location-dot" />
                            <span className="">377, Wallstacker, Kintambo</span>
                        </div>
                    </div>
                </div>
            </Container>

        </header>
    )
}