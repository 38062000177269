type props = {
    title : string
    image : string
    link : string
}

export default function ClientLogo({title, image, link} : props){
    return(
        <div  className="w-36 h-28 border border-opacity-10 hover:border-slate-400 object-cover  flex justify-center items-center rounded-md overflow-hidden">
            <img className=" w-36 object-cover" src={image} alt={`SSA.Sarl client ${title}`} />
        </div>
    )
}