import Container from './container';
export default function NewsLetter(){
    return (
        <section className="bg-primary text-white">
            <Container className="grid md:grid-cols-2 items-center gap-y-4 py-20">
                <h1 className="text-4xl font-bold">Rester informé</h1>
                <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-0 p-5 border shadow-sm rounded-xl">
                    <input type="text" placeholder="Entrer votre email" className="bg-transparent w-full placeholder:text-white" />
                    <button className="flex justify-center items-center gap-2 border border-transparent bg-primary-deep hover:border-white text-white px-4 py-2 rounded-md">
                        Enregistrer <i className="fa-regular fa-envelope" />
                    </button>
                </div>
            </Container>
        </section>
    )
}