import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Footer, Header } from "./components";
import { AboutUs, Contact, Home, PageNotFound, Services } from "./pages";
import ServiceDetails from './pages/service-details';

function App() {
  return (
    <BrowserRouter >
      <Header />
      <Routes>
        <Route path="" element={<Home />} />
        {/* <Route path="about-us" element={<AboutUs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="services" element={<Services />} />
        <Route path="services/*" element={<ServiceDetails />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
