import { Link } from "react-router-dom";
import { ClientLogo, ServiceCard, TeamMemberCard } from "../components";
import Container from "../components/container";
import MyForm, { InputFieldCustomSelect, InputFieldTextArea } from "../components/my-form";
import Slider from "../components/slider";
import emailjs, {EmailJSResponseStatus} from "@emailjs/browser"
import {useState} from "react";

export default function Home() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    return (
        <>
            {/* hero-main section */}
            <section className="relative bg-slate-100 h-screen lg:h-[600px]">
                <div className="absolute inset-0 md:grid md:grid-cols-2 lg:grid-cols-4">
                    <img className="hidden md:block object-cover object-center h-full w-full" src="/images/smart-sport-agency-istockphoto-475077996-612x612.jpg" alt="" />
                    <img className="hidden md:block object-cover object-center h-full w-full" src="/images/smart-sport-agency-istockphoto-1266971680-612x612.jpg" alt="" />
                    <img className="hidden lg:block object-cover object-center h-full w-full" src="/images/smart-sports-agency-competition.jpg" alt="" />
                    <img className="hidden lg:block object-cover object-center h-full w-full" src="/images/smart-sports-agency-judo.jpg" alt="" />
                    <img className="md:hidden object-cover object-center h-full w-full" src="/images/smart-sport-agency-football-5587398_1920.jpg" alt="" />
                </div>
                <div className="flex items-center text-white relative z-10 w-full bg-black bg-opacity-50 h-full py-14">
                    <Container className="text-center md:text-left">
                        <h1 className="text-4xl lg:text-7xl font-bold">Smart Sport Agency Sarl</h1>
                        <p className="text-lg max-w-xl my-4">
                            Cabinet d’étude et de conseil sportif basé en République Démocratique du Congo avec un important réseau d'affaires en Afrique, en Europe et dans le reste du monde.
                        </p>
                        <Link className="block mx-auto md:mx-0 w-fit bg-primary hover:bg-secondary rounded-3xl px-8 py-2 text-lg" to="#contact">Nous contacter</Link>
                    </Container>
                </div>
            </section>

            {/* about area starts */}
            <section id="about-us" >
                <Container className="py-28 flex flex-col gap-y-8">
                    <div className="text-default grid sm:grid-cols-2 gap-x-10 items-center">
                        <div className="h-full w-full relative hidden md:block">
                            <img className="absolute right-0 bottom-0 translate-y-1/3 translate-x-1/3" src="/images/smart-sports-agency-banner-circle.png" alt="" />
                            <img className="w-full h-full md:border-y-[1rem] md:border-r-[1rem] md:border-primary" src="/images/smart-sport-agency-istockphoto-170173386-612x612.jpg" alt="" />
                        </div>
                        <div className="lg:pl-12 flex flex-col text-center md:text-left gap-y-5">
                            <h1 className="text-secondary text-2xl md:text-5xl font-semibold">A propos de nous</h1>
                            <p className="font-semibold italic text-lg">
                                « Ce n'est pas le football qui développe un pays, mais ce sont les hommes et l'éducation qu'on les transmet. »
                            </p>
                            <p className="text-justify">
                                <span className="text-secondary font-semibold">SSA.Sarl </span>se trouve être un partenaire sérieux dans l’environnement sportif congolais. Avec des consultants sportifs certifiés et des partenaires étrangers capables de satisfaire aux différentes préoccupations de nos clients. Nous répondons avec expertise et maîtrise à tout type des demandes qui nous sont soumises avec une équipe composée essentiellement des managers sportifs, des juristes du sport, des économistes, etc.
                            </p>
                            <img className="md:hidden w-full h-full md:border-y-[1rem] md:border-r-[1rem] md:border-primary" src="/images/smart-sport-agency-istockphoto-170173386-612x612.jpg" alt="" />
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-x-10  py-10 gap-y-10 text-center md:text-left">
                        <div className="flex flex-col gap-y-5">
                            <h1 className="text-secondary text-2xl sm:text-3xl font-bold">Nos objectifs</h1>
                            {/*<p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white md:text-black text-lg">Nos différents objectifs sont :</p>*/}
                            {/*<p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white md:text-black text-lg">*/}
                                <ol className="p-4 md:p-0 bg-secondary md:bg-transparent text-white text-justify md:text-black text-lg">
                                    <li>1. Développer à long terme les compétences des animateurs des institutions sportives en République Démocratique du Congo.</li>
                                    <li>2. Contributuer dans l’amélioration des méthodes de gestion, des conditions techniques et sécuritaire dans le domaine du sport.</li>
                                    <li>3. Encourager et orienter nos différents partenaires dans l’atteinte de leurs objectifs sportifs.</li>
                                    <li>4. Accompagner l’Etat congolais dans le développement du secteur sportif par notre expertise.</li>
                                </ol>
                            {/*</p>*/}
                        </div>
                        <div className="flex flex-col gap-y-5">
                            <h1 className="text-secondary text-2xl sm:text-3xl font-bold">Notre mision</h1>
                            <p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white text-justify md:text-black text-lg">
                                Les différentes connaissances acquises dans ce domaine du sport par nos consultants et les partenariats conclus créent des opportunités de développement institutionnel, d’affaires et de développement dans la pratique du jeu à tous les niveaux, dans des disciplines pour lesquelles nous avons de la maîtrise à savoir le football, le Judo, le handball, etc.
                            </p>

                            <p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white text-justify md:text-black text-lg">

                                <span className="font-semibold text-default italic">SSA.Sarl </span> croit fermement aux talents sportifs que regorgent la République Démocratique du Congo et au développement du sport. C’est dans ce cadre que nous avons décidé de nous impliquer dans le succès que va connaître ce domaine dans un avenir très proche.
                            </p>
                        </div>

                    </div>
                </Container>
            </section>
            {/* about area ends */}

            {/* services  area starts */}
            <section id="services" className="text-white bg-cover bg-fixed relative after:absolute after:inset-0 after:bg-black after:bg-opacity-40" style={ { backgroundImage : "url(/images/smart-sport-agency-istockphoto-1223619342-612x612.jpg)" }}>
                <div className="relative z-10 bg-black bg-opacity-30">
                    <Container className="flex flex-col gap-y-10">
                        <div className="text-center md:text-left grid sm:grid-cols-2 gap-x-10 items-center py-10">
                            <div>
                                <h1 className="text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold">Domaines d'activités</h1>
                            </div>
                            <div className="">
                                <p className="text-lg text-justify">
                                    Conseiller et accompagner les sportifs ainsi que les institutions sportives ou entreprises sponsors (sponsoring sportif) dans la conclusion de contrat sportif
                                    (contrat de prestation), contrat de sponsoring sportif suivant les règles de négociation de contrat de travail à travers une médiation efficace.
                                    Elle conseille également les personnes physiques ou morales dans tout type de contentieux sportif.
                                </p>
                            </div>

                        </div>
                    </Container>
                </div>
                <Container className="relative z-10 flex flex-wrap justify-center gap-y-6 pt-12 py-24">
                    <ServiceCard
                        title="Gestion et planification"
                        excerpt="Gestion et planification des événements et manifestations sportives"
                        slug="conseil-et-strategie"
                        image="/images/service-pic.jpg"
                    />
                    <ServiceCard
                        title="Elaboration des projets"
                        excerpt="Elaboration des projets de développement sportif institutionnelpublic et privée (fédération, clubs, etc.) "
                        slug="promotion-et-publicite"
                        image="/images/service-picture.jpg"
                    />
                    <ServiceCard
                        title="Stratégie de management du sport"
                        excerpt="Stratégie de management du sport (gestion des institutionssportives, gestion de carrière sportive, etc.)"
                        slug="promotion-et-publicite"
                        image="/images/service-pic.jpg"
                    />
                    <ServiceCard
                        title="Stratégie de marketing sportif"
                        excerpt="Stratégie de marketing sportif (sponsoring, branding, etc.)"
                        slug="promotion-et-publicite"
                        image="/images/service-picture.jpg"
                    />
                    <ServiceCard
                        title="Communication sportive"
                        excerpt="Communication sportive (importance des médias sociaux dans lesecteur sportif)"
                        slug="promotion-et-publicite"
                        image="/images/service-pic.jpg"
                    />
                    <ServiceCard
                        title="Stratégie financière et économie du sport"
                        excerpt="Stratégie financière et économie du sport"
                        slug="promotion-et-publicite"
                        image="/images/service-picture.jpg"
                    />
                    <ServiceCard
                        title="Résolution des litiges sportifs"
                        excerpt="Résolution des litiges sportifs (dopage, etc.)"
                        slug="promotion-et-publicite"
                        image="/images/service-pic.jpg"
                    />
                    <ServiceCard
                        title="Accompagnement dans l’organisation"
                        excerpt="Accompagnement dans l’organisation des évènements et manifestations sportives en RDC."
                        slug="conseil-et-strategie"
                        image="/images/service-picture.jpg"
                    />
                </Container>
            </section>
            {/* services area ends */}

            {/* réalisations area starts*/}
            <section id="realisations" className="bg-slate-100 pt-16 text-default">
                <Container className="text-center md:text-left flex flex-col gap-y-10 mx-0">
                    <div className="grid sm:grid-cols-2 gap-x-10 items-center pt-10 md:py-10">
                        <div className="px-8 pb-10 md:pb-0">
                            <h1 className="text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold text-secondary">Réalisations</h1>
                            <p className="text-lg mt-4 text-default text-justify">
                                Ce n’est pas le football qui développe un pays, mais ce sont les hommes et l’éducation qu’on les transmet.
                                {/* <span className="text-primary italic">l'équité, le respect, la maitrise, le patriotisme, etc.</span> */}
                            </p>
                        </div>
                        <div className="flex flex-wrap gap-4 py-8 md:py-0 bg-white md:bg-transparent">
                            <div className="w-fit mx-auto p-4 flex flex-col items-center justify-center gap-4">
                                <h3 className="font-bold text-secondary text-5xl">8<span className=" scale-125">+</span></h3>
                                <p className="text-xl font-semibold">Clients</p>
                            </div>
                            <div className="w-fit mx-auto p-4 flex flex-col items-center justify-center gap-4">
                                <h3 className="font-bold text-secondary text-5xl">20<span className=" scale-125">+</span></h3>
                                <p className="text-xl font-semibold">Partenaires</p>
                            </div>
                            <div className="w-fit mx-auto p-4 flex flex-col items-center justify-center gap-4">
                                <h3 className="font-bold text-secondary text-5xl">15<span className=" scale-125">+</span></h3>
                                <p className="text-xl font-semibold">Projets</p>
                            </div>
                        </div>

                    </div>
                </Container>
                <div className="text-white hidden sm:grid sm:grid-cols-2 lg:grid-cols-4">
                    <div className="relative after:absolute after:inset-0 after:bg-black after:bg-opacity-50 flex items-center justify-center h-72 w-full bg-cover bg-right text-3xl font-semibold" style={{ backgroundImage : "url(/images/smaprt-sport-agency-istockphoto-1067803368-612x6122.jpg)"}}>
                        <span className="relative z-20">Travail</span>
                    </div>
                    <div className="relative after:absolute after:inset-0 after:bg-black after:bg-opacity-50 flex items-center justify-center h-72 w-full bg-cover bg-center text-3xl font-semibold" style={{ backgroundImage : "url(/images/smart-sport-agency-istockphoto-1339797427-612x612.jpg)"}}>
                        <span className="relative z-20">Discipline</span>
                    </div>
                    <div className="relative after:absolute after:inset-0 after:bg-black after:bg-opacity-50 flex items-center justify-center h-72 w-full bg-cover bg-center text-3xl font-semibold" style={{ backgroundImage : "url(/images/smart-sport-agency-istockphoto-1204020482-612x612.jpg)"}}>
                        <span className="relative z-20">Ethique</span>
                    </div>
                    <div className="relative after:absolute after:inset-0 after:bg-black after:bg-opacity-50 flex items-center justify-center h-72 w-full bg-cover bg-center text-3xl font-semibold" style={{ backgroundImage : "url(/images/smart-sport-agency-istockphoto-475077996-612x612.jpg)"}}>
                        <span className="relative z-20">Concentration</span>
                    </div>
                </div>
            </section>
            {/* réalisations area ends */}


            {/* team section */}
            <section className="text-default bg-primary bg-opacity-70 md:bg-transparent">
                <div className="container mx-auto px-4 md:px-0 py-24">
                    <h1 className="text-white md:text-secondary text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold text-center">Equipe</h1>
                    <p className="text-white md:text-default sm:text-lg text-center max-w-xl mx-auto mt-4">
                        Elle accompagnera toutes les sociétés sportives et institutions sportives désireuses d'avoir un contrat avec un équipementier
                    </p>
                    <div className="flex flex-wrap justify-center gap-8 mt-10">
                        <TeamMemberCard name="Obed MUTENGA" position="Directeur général (FIFA/CIES Alumni, Egypte 15e édition)." image="/images/team/obed.jpeg" />
                        <TeamMemberCard name="Murdoch KALONJI" position="Secrétaire Général et Webmaster" image="/images/team/murdoch.jpg" />
                        <TeamMemberCard name="Asnath ILUNGA" position="Responsable Marketing, Communication et finance" image="/images/team/smart-sport-agency-ilunga-miozi-asnath.jpg" />
                        <TeamMemberCard name="Ledjo KAMBAMBA" position="Responsable des projets sportifs" image="/images/team/smart-sport-agency-kabamba-makabila-ledjo.jpg" />

                        {/* <TeamMemberCard name="MUTENGA TSHYEDI Obed" position="Directeur général" image="/images/team/smart-sport-agency-mutenga-tshiyedi-obed.jpg" />
                        <TeamMemberCard name="KALONJI MBAYA Michée" position="Financier" image="/images/team/smart-sport-agency-kalonji-mbala-michee.jpg" />
                        <TeamMemberCard name="KAMBAMBA MAKABILA Ledjo" position="Chargé des projets" image="/images/team/smart-sport-agency-kabamba-makabila-ledjo.jpg" />
                        <TeamMemberCard name="ILUNGA MIOZI Asnath" position="Secrétaire générale et chargée des communicationions" image="/images/team/smart-sport-agency-ilunga-miozi-asnath.jpg" /> */}
                    </div>
                </div>
            </section>
             {/* contact-form area starts */}
            <section id="contact" className="text-white bg-cover bg-center relative after:absolute after:inset-0 after:bg-black after:bg-opacity-60 py-28" style={{ backgroundImage : "url(/images/istockphoto-143921828-612x612.jpg)"}}>
                <Container className="relative z-10">
                    <h1 className="text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold">Contact</h1>
                    <div className="hidden mt-14 md:flex flex-wrap flex-row justify-center items-center gap-y-12 gap-x-6 md:gap-x-16 ">
                            <a href="tel:+243844952771" className="flex flex-col items-center gap-y-5">
                                <i className="fa-solid fa-phone text-3xl text-white" />
                                <span className="">+243 844 952 771</span>
                            </a>
                            {/*<div className="flex flex-col items-center gap-y-5">*/}
                            {/*    <i className="fa-solid fa-location-dot text-3xl text-white" />*/}
                            {/*    <span className="">377 Wakami, Kintambo</span>*/}
                            {/*</div>*/}
                            <a href="mailto:contact@ssadrc.com" className="flex flex-col items-center gap-y-5">
                                <i className="fa-regular fa-envelope text-3xl text-white" />
                                <span className="">contact@ssadrc.com</span>
                            </a>
                    </div>
                    <MyForm
                        className='flex flex-wrap justify-between mt-8 md:mt-14 max-w-2xl mx-auto'
                        onSubmit={ async (fields,)  => {
                            setIsSubmitting(true)
                            console.log("form ==> ", fields)
                            try {
                               const result = await emailjs.send(
                                    'service_qf275h2',
                                    'template_57dznrp',
                                   fields,
                                    {
                                        publicKey: 'osiNwt610-fXn1yTV',
                                    },
                                );
                                console.log('SUCCESS! ===>', result);
                                setIsSubmitting(false)
                            } catch (err) {
                                if (err instanceof EmailJSResponseStatus) {
                                    console.log('EMAILJS FAILED...', err);
                                    return;
                                }

                                console.log('ERROR', err);
                            }
                        }}
                        fields={{
                            name : {
                                id : 'fullname',
                                placeholder : 'Entrez votre nom',
                                label : 'Nom',
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : 'At last 10 character',
                            },
                            email : {
                                id : 'email',
                                placeholder : 'Entrez votre email',
                                label : 'Email',
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : 'email is not valid',
                            },
                            phone : {
                                id : 'phone',
                                placeholder : 'Entrez le numéro de téléphone',
                                label : 'Téléphone',
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : '',
                            },
                            subject : {
                                id : 'subject',
                                label : 'Sujet',
                                placeholder : "Choisir le sujet",
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                extraData : { options : [{label :"S'informer", value : "001 Information"}, {label : "Question", value : "002 Question"}, {label:"Autre", value:"003 Autre"}] },
                                renderFieldComponent : InputFieldCustomSelect
                            },
                            message : {
                                label : "Message",
                                placeholder : "Ecrivez votre message",
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : "too short and invalid",
                                renderFieldComponent : InputFieldTextArea
                            }

                        }}

                    >

                        <div className="flex-1 flex justify-end gap-4 my-4">

                            <button
                                className="w-full disabled:events-none flex gap-2 items-center justify-center text-center bg-primary hover:bg-primary-deep py-5 rounded-md text-white text-2xl"
                                disabled={isSubmitting}
                            >
                                <span>Envoyer</span>
                                 { isSubmitting && <span className='fa-solid fa-circle-notch animate-spin' />   }
                            </button>
                        </div>

                    </MyForm>
                </Container>
            </section>
            {/* contact-form area ends */}

            {/* clients area starts*/}
            <section className="hidden md:block bg-slate-100 py-24">
                <Container className="flex flex-col gap-y-10">
                    <div className="grid sm:grid-cols-2 gap-x-10 items-center">
                        <div>
                            <h1 className="text-secondary text-4xl lg:text-5xl 2xl:text-7xl font-semibold">Ils nous ont fait confiance</h1>
                        </div>
                        <div>
                            <p className="text-lg text-default text-justify">
                                Le sport peut enseigner des valeurs telles que: l'équité, le respect, la maîtrise, le patriotisme, le travail d'équipe, etc.
                                Conscient de la fonction socio-éducative du sport, nous, <span className="font-semibold text-secondary italic">SSA.Sarl</span>,
                                avons décidé de nous lancer  dans l’encadrement de la jeunesse sportive dans le but de les aider à réussir leurs carrières sportives.
                            </p>
                        </div>

                    </div>
                    <div className="flex flex-wrap justify-center gap-8">
                        {/*<ClientLogo title="Sport and Justice" image="/images/logos/sportjustice.jpeg" link="" />*/}
                        <ClientLogo title="Athletes Sphere Management" image="/images/logos/athlets.jpeg" link="" />
                        {/*<ClientLogo title="Fifa CIES" image="/images/logos/fifacies.jpeg" link="" />*/}
                        {/*<ClientLogo title="Real Betis" image="/images/logos/realbetis.jpeg" link="" />*/}
                        <ClientLogo title="Tinmahn Sport" image="/images/logos/tinmahn.jpeg" link="" />
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    </div>
                </Container>
            </section>

            {/* clients area ends */}
            <section className="md:hidden py-24">
                <Container className="text-center">
                    <h1 className="text-secondary text-4xl lg:text-5xl 2xl:text-7xl font-semibold">Ils nous font confiance</h1>
                    <p className="text-lg mt-4 mb-8 text-default">
                        Le sport peut enseigner des valeurs telles que: l'équité, le respect, la maîtrise, le patriotisme, le travail d'équipe, etc.
                    </p>
                    <Slider
                        className="flex flex-wrap justify-center gap-8"
                        // slidesPerView={2}
                        // autoplay
                        // spaceBetween={10}
                        loop
                        grabCursor
                        autoplay={{ delay : 2500, disableOnInteraction : false, pauseOnMouseEnter : true}}
                        slidesPerView={2}
                        spaceBetween={10}
                        // pagination={{clickable : true}}
                    >

                        <ClientLogo title="Tinmahn Sport" image="/images/logos/tinmahn.jpeg" link="" />
                        <ClientLogo title="Athletes Sphere Management" image="/images/logos/athlets.jpeg" link="" />

                        {/*<ClientLogo title="Tinmahn Sport" image="/images/logos/tinmahn.jpeg" link="" />*/}

                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}

                    </Slider>
                </Container>
            </section>

        </>
    )
};
